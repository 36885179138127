import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Page from "src/components/Page";
import useAuth from "src/hooks/useAuth";
import useQueryParams from "src/hooks/useQueryParams";
import { setSession } from "src/utils/jwt";
import Header from "./components/Header";
import MainSection from "./components/MainSection";

const Login = () => {
  const { queryObject } = useQueryParams();
  const { backofficeLogin } = useAuth();

  const { email, secret_key } = queryObject;
  useEffect(() => {
    const handleBackofficeLogin = async () => {
      try {
        await backofficeLogin({ email, secret_key });
      } catch (err) {
        console.log(err);
      }
    };
    if (email && secret_key) {
      handleBackofficeLogin();
    }
  }, [email, secret_key]);

  const isSubAdmin = localStorage.getItem("isSubAdmin") === "true";

  if (isSubAdmin) {
    const menu_lists = JSON.parse(localStorage.getItem("menu"));
    const [menu] = menu_lists;
    const { path, children } = menu?.items[0] || {};

    if (path.includes("dashboard")) {
      window.location = children[0]?.path;
      return;
    }
    window.location = path;
    return;
  }
  return (
    <Page
      title="Login"
      sx={{
        height: "100%",
      }}
    >
      <Header />
      <MainSection />
    </Page>
  );
};

export default Login;
